/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
  white-space: break-spaces;
}

.about .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #173b6c;
}
  
.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.about .content ul strong {
  margin-right: 10px;
}

.about .content ul i {
  font-size: 16px;
  margin-right: 5px;
  color: #149ddd;
  line-height: 0;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .AboutForm {
  width: 100%;
  max-height: 65vh;
  border-radius: 5px;
}

.about img {
  border-radius: 5px;
  box-shadow: 1px 1px 5px black;
}