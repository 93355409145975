.ResumeItemForm .form-container {
    height: 65vh;
    width: 100%;
    overflow-y: scroll;
    border-radius: 5px;
    padding: 10px;
    background-color: rgba(248, 240, 240, 0.8);
    text-align: center;
}

.ResumeItemForm h4 {
    font-weight: 800;
}

.ResumeItemForm label {
    font-size: 13px;
}