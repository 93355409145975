/* Desktop Navigation */
.nav-menu {
    padding: 30px 0 0 0;
  }
  
  .nav-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .nav-menu>ul>li {
    position: relative;
    white-space: nowrap;
  }
  
  .nav-menu a,
  .nav-menu a:focus {
    display: flex;
    align-items: center;
    color: #a8a9b4;
    padding: 12px 15px;
    margin-bottom: 8px;
    transition: 0.3s;
    font-size: 15px;
  }
  
  .nav-menu a i,
  .nav-menu a:focus i {
    font-size: 24px;
    padding-right: 8px;
    color: #6f7180;
  }
  
  .nav-menu a:hover,
  .nav-menu .active,
  .nav-menu .active:focus,
  .nav-menu li:hover>a {
    text-decoration: none;
    color: #fff;
  }
  
  .nav-menu a:hover i,
  .nav-menu .active i,
  .nav-menu .active:focus i,
  .nav-menu li:hover>a i {
    color: #149ddd;
  }
  
  /* Mobile Navigation */
  .mobile-nav-toggle {
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 9998;
    border: 0;
    font-size: 24px;
    transition: all 0.4s;
    outline: none !important;
    background-color: #149ddd;
    color: #fff;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    border-radius: 50px;
    cursor: pointer;
  }
  
  .mobile-nav-active {
    overflow: hidden;
  }
  
  .mobile-nav-active #header {
    left: 0;
  }