/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
    padding-top: 40px;
    white-space: break-spaces;
    overflow: auto;
}
  
.portfolio-details .portfolio-details-slider img {
    width: 100%;
}
  
.portfolio-details .portfolio-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
}
  
.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #149ddd;
}
  
.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #149ddd;
}
  
.portfolio-details .portfolio-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(5, 13, 24, 0.08);
}
  
.portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
}
  
.portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}
  
.portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
}
  
.portfolio-details .portfolio-description {
    padding-top: 30px;
}
  
.portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}
  
.portfolio-details .portfolio-description p {
    padding: 0;
}

.slider {
    width: 650px;
    height: 375px;
}

@media (max-width: 768px) {
    .slider {
        width: 600px;
        height: 300px;
    }
    .portfolio-details .container {
        margin: 0 auto;
    }
}

@media (max-width: 1024px) {
    .slider {
        width: 550px;
        height: 300px;
    }
}

@media (max-width: 1200px) and (min-width: 1025px) {
    .slider {
        width: 550px;
        height: 300px;
    }
}