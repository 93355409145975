
/* #hero {
    width: 100vw;
    height: 100vh;
} */

#hero:before {
    width: 100vw;
    height: 100vh;
    content: "";
    background: rgba(5, 13, 24, 0.3);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

#hero .hero-container {
    position: absolute;
    z-index: 2;
    min-width: 300px;
    /* background-color: red; */
}

#hero h1 {
    margin: 0 0 10px 0;
    font-size: 64px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
}

#hero p {
    color: #fff;
    margin-bottom: 50px;
    font-size: 26px;
    font-family: "Poppins", sans-serif;
}

#hero p span {
    color: #fff;
    padding-bottom: 4px;
    letter-spacing: 1px;
    border-bottom: 3px solid #149ddd;
}

/* @media (min-width: 1024px) {
    #hero {
        background-attachment: fixed;
    }
} */

@media (max-width: 768px) {
    #hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    #hero h2 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }
}

/* @media (max-width: 1199px) {
  
    #hero {
      left: -300;
    }
  } */