.ProjectItemImageForm .form-container {
    max-height: 28vh;
    width: 50%;
    overflow-y: scroll;
    border-radius: 5px;
    padding: 10px;
    background-color: rgba(248, 240, 240, 0.8);
    text-align: center;
    margin: 0 auto;
}

.ProjectItemImageForm h4 {
    font-weight: 800;
}

.ProjectItemImageForm label {
    font-size: 13px;
}